import {web3} from "@project-serum/anchor";
import {Client as TokenSwapClient, PoolMath} from "@/api/token_swap";
import {CreateMint, Token2022Client} from "@/api/token2022/token_2022";
import {
	ASSOCIATED_TOKEN_PROGRAM_ID,
	createAssociatedTokenAccountInstruction,
	ExtensionType,
	getAssociatedTokenAddressSync,
	TOKEN_2022_PROGRAM_ID,
	TOKEN_PROGRAM_ID
} from "@solana/spl-token";
import {Metadata, PROGRAM_ID} from "@metaplex-foundation/mpl-token-metadata";
import {TokenInput} from "@/api/token_swap/layouts";

import {SWAP_PROGRAM_OWNER_FEE_ADDRESS} from "@/api/token_swap/create_token_pool";
import {METADATA_2022_PROGRAM_ID, SWAP_PROGRAM_ID, WSOL} from "@/api/token_swap/constants";
import BN from "bn.js";
import {bool, struct, u64, u8} from "@/api/marshmallow";
import {sha256} from "js-sha256";
import {Market, PoolLayout} from "@/api/nft_fraction/layouts";
import {getParsedNftAccountsByOwner} from "@/api/nft_fraction/getParsedNFTAccountsByOwner";
import {ComputeBudgetProgram} from "@solana/web3.js";

export default class Client {

	//TODO Add permissionless fee clear where possible

	connection: web3.Connection;

	programID: web3.PublicKey = new web3.PublicKey("2TnHAQqFZU1skoFo3Zr5GJ4pKZf2ec2pFKW4ZRX5BPsD")

	swapClient: TokenSwapClient;
	token2022: Token2022Client;

	constructor(connection: web3.Connection) {
		this.connection = connection

		this.swapClient = new TokenSwapClient(connection)
		this.token2022 = new Token2022Client(connection)
	}

	getCollectionPoolKey(collection: web3.PublicKey) {
		const [collectionPool] = web3.PublicKey.findProgramAddressSync([collection.toBuffer()], this.programID)
		return collectionPool
	}

	async getPools() {
		const resp = await this.connection.getProgramAccounts(this.programID, {
			commitment: "confirmed",
			filters: [
				{
					dataSize: 8 + 1 + 32 + 32,
				},
			]
		})
		return resp.map((m) => {
			return {pubkey: m.pubkey, account: PoolLayout.decode(m.account.data)}
		})
	}

	async getPool(pool: web3.PublicKey) {
		const resp = await this.connection.getAccountInfo(pool, "confirmed")
		if (!resp)
			return null

		return PoolLayout.decode(resp.data)
	}

	async getPoolItems(pool: web3.PublicKey) {
		return getParsedNftAccountsByOwner({
			publicAddress: pool,
			connection: this.connection
		})
	}

	async getAllMarkets() {
		const resp = await this.connection.getProgramAccounts(this.programID, {
			commitment: "confirmed",
			filters: [
				{
					dataSize: 8 + 1 + 32 + 32 + 32,
				},
			]
		})
		return resp.map((m) => {
			return {pubkey: m.pubkey, account: Market.decode(m.account.data)}
		})
	}

	async getMarket(nftCollection: web3.PublicKey, quoteMint: web3.PublicKey = WSOL) {
		const [market] = web3.PublicKey.findProgramAddressSync([nftCollection.toBuffer(), quoteMint.toBuffer()], this.programID)
		const resp = await this.connection.getAccountInfo(market, "confirmed")
		if (!resp)
			return

		return Market.decode(resp.data)
	}

	async getMarkets(nftCollection: web3.PublicKey) {
		const resp = await this.connection.getProgramAccounts(this.programID, {
			commitment: "confirmed",
			filters: [
				{
					dataSize: 8 + 1 + 32 + 32 + 32,
				},
				{
					memcmp: {
						offset: 8 + 1,
						bytes: nftCollection.toString(),
					}
				}
			]
		})
		return resp.map((m) => {
			return {pubkey: m.pubkey, account: Market.decode(m.account.data)}
		})
	}

	async getCollection(collection: web3.PublicKey) {
		const metaPDA = this.token2022.getMetadataPDA(collection, PROGRAM_ID)
		const metadata = await Metadata.fromAccountAddress(this.connection, metaPDA, "confirmed")

		return this.sanitizeTokenMeta(metadata)
	}

	async getToken(collection: web3.PublicKey) {
		const metaPDA = this.token2022.getMetadataPDA(collection)
		const metadata = await Metadata.fromAccountAddress(this.connection, metaPDA, "confirmed")

		return this.sanitizeTokenMeta(metadata)
	}

	/**
	 * Sell a NFT for SOL
	 */
	async sellNFT(payer: web3.PublicKey, collection: web3.PublicKey, nft: web3.PublicKey, minimumAmountOut: number): Promise<web3.Transaction> {
		const collectionPool = this.getCollectionPoolKey(collection)
		const pool = await this.swapClient.getPool(collectionPool)
		console.log("sellNFT:Pool", collectionPool.toString(), pool)

		//Exchange for 10_000 cTOK
		const txn = await this.exchangeNFT(payer, collection, nft)

		//Exchange 10_000 cTOK for SOL
		const swapTxn = await this.swapClient.createSwapTransaction(
			payer,
			collectionPool,
			new TokenInput(pool.account.mintB, 10_000),
			new TokenInput(WSOL, minimumAmountOut, TOKEN_PROGRAM_ID),
			pool.account,
			1,
			minimumAmountOut
		)
		txn.add(...swapTxn.instructions)

		return txn
	}

	/**
	 * Buy a NFT with SOL
	 *
	 * @param payer of the transaction
	 * @param collection to trade on
	 * @param nft to purchase
	 * @param amountIn Amount in SOL to spend on a NFT
	 */
	async buyNFT(payer: web3.PublicKey, collection: web3.PublicKey, nft: web3.PublicKey, amountIn: number): Promise<web3.Transaction> {
		const [collectionPool] = web3.PublicKey.findProgramAddressSync([collection.toBuffer()], this.programID)
		const pool = await this.swapClient.getPool(collectionPool)
		console.log("buyNFT:Pool", collectionPool.toString(), pool)

		//Exchange SOL for 10_000 cTOK
		const txn = await this.swapClient.createSwapTransaction(
			payer,
			collectionPool,
			new TokenInput(WSOL, amountIn, TOKEN_PROGRAM_ID),
			new TokenInput(pool.account.mintB, 10_000),
			pool.account,
			amountIn,
			10_000)

		//Exchange 10_000 cTOK for NFT
		const exchangeTxn = await this.exchangeNFT(payer, collection, nft)
		txn.add(...exchangeTxn.instructions)

		return txn
	}

	/**
	 * Add liquidity to the pool using a NFT
	 * @param owner
	 * @param collection
	 * @param nft
	 */
	async addLiquidity(owner: web3.PublicKey, collection: web3.PublicKey, nft: web3.PublicKey): Promise<web3.Transaction> {
		console.log("addLiquidity", {
			owner: owner.toString(),
			collection: collection.toString(),
			nft: nft.toString(),
		})
		// Exchange NFT for cTOK
		// const txn = await this.exchangeNFT(owner, collection, nft)
		const market = await this.getMarket(collection, WSOL)
		if (!market)
			return Promise.reject("collection market not found")

		console.log("Market", JSON.parse(JSON.stringify(market)))

		const [collectionPool] = web3.PublicKey.findProgramAddressSync([collection.toBuffer()], this.programID);
		const [swapAuthority] = web3.PublicKey.findProgramAddressSync([market?.swapPool!.toBuffer()], SWAP_PROGRAM_ID);

		const pool = await this.swapClient.getPool(market?.swapPool!)
		const poolDetail = await this.swapClient.getPoolDetail(market?.swapPool!, pool.account, owner)

		const spotPrice = poolDetail?.tokenAccountB.tokenAmount.amount / poolDetail?.tokenAccountA.tokenAmount.amount;
		const tokenBAmount = Math.ceil(spotPrice * 100_000)

		console.log("NFTPool", {pool: JSON.parse(JSON.stringify(pool)), poolDetail, spotPrice, minOut: tokenBAmount / Math.pow(10, 9)})

		const minAmountOut = PoolMath.lpTokensReceived(100_000, poolDetail.tokenAccountA.tokenAmount.amount, poolDetail.mintLp.supply)
		const slipAmount = new BN(minAmountOut).mul(new BN(1)).div(new BN(10_000))
		const minAmountOutSlip = new BN(minAmountOut).sub(slipAmount)


		const nftMetadata = this.token2022.getMetadataPDA(nft, PROGRAM_ID)
		const collectionTokenMint = pool.account.mintA

		const ownerQuoteAccount = getAssociatedTokenAddressSync(pool.account.mintB, owner, false) //TODO auto detect program_id

		const ownerTokenAccount = getAssociatedTokenAddressSync(collectionTokenMint, owner, false, TOKEN_2022_PROGRAM_ID)
		const poolTokenAccount = getAssociatedTokenAddressSync(collectionTokenMint, collectionPool, true, TOKEN_2022_PROGRAM_ID)

		const ownerPoolAccount = getAssociatedTokenAddressSync(pool.account.tokenPool, owner, false, TOKEN_2022_PROGRAM_ID) //LP Mint

		const ownerNftAccount = getAssociatedTokenAddressSync(nft, owner, false)
		const poolNftAccount = getAssociatedTokenAddressSync(nft, collectionPool, true)

		const txn = new web3.Transaction()
		txn.add(ComputeBudgetProgram.setComputeUnitLimit({units: 250000}))

		if (!await this.accountExists(ownerQuoteAccount))
			txn.add(createAssociatedTokenAccountInstruction(owner, ownerQuoteAccount, owner, pool.account.mintB))

		if (!await this.accountExists(ownerTokenAccount))
			txn.add(createAssociatedTokenAccountInstruction(owner, ownerTokenAccount, owner, collectionTokenMint, TOKEN_2022_PROGRAM_ID))

		if (!await this.accountExists(poolTokenAccount))
			txn.add(createAssociatedTokenAccountInstruction(owner, poolTokenAccount, collectionPool, collectionTokenMint, TOKEN_2022_PROGRAM_ID))


		txn.add(this.createAddLiquidityInstruction({
			owner: owner,
			pool: collectionPool,
			nftMint: nft,
			nftMetadata: nftMetadata,
			collectionTokenMint: collectionTokenMint,
			ownerTokenAccount: ownerTokenAccount,
			ownerQuoteAccount: ownerQuoteAccount,
			poolTokenAccount: poolTokenAccount,
			ownerNftAccount: ownerNftAccount,
			poolNftAccount: poolNftAccount,
			ownerPoolAccount: ownerPoolAccount,
			swapPool: market.swapPool,
			swapAuthority: swapAuthority,
			swapLpMint: pool.account.tokenPool,
			quoteTokenMint: market.quoteMint,
			swapTokenAccountA: pool.account.tokenAccountA,
			swapTokenAccountB: pool.account.tokenAccountB,
			nftTokenProgram: TOKEN_PROGRAM_ID,
			tokenProgram: TOKEN_2022_PROGRAM_ID,
			quoteTokenProgram: TOKEN_PROGRAM_ID, //TODO Get correct ID
			swapProgram: SWAP_PROGRAM_ID,
			nftTokenMetadataProgram: PROGRAM_ID,
			associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
			systemProgram: web3.SystemProgram.programId,
			poolTokenAmount: minAmountOut,
			maximumTokenA: new BN(100_000),
			maximumTokenB: new BN(tokenBAmount),
		}))

		return txn
	}

	createAddLiquidityInstruction(args: {
		owner: web3.PublicKey,
		pool: web3.PublicKey,
		nftMint: web3.PublicKey,
		nftMetadata: web3.PublicKey,
		collectionTokenMint: web3.PublicKey,
		ownerTokenAccount: web3.PublicKey,
		ownerQuoteAccount: web3.PublicKey,
		poolTokenAccount: web3.PublicKey,
		ownerNftAccount: web3.PublicKey,
		poolNftAccount: web3.PublicKey,
		ownerPoolAccount: web3.PublicKey, //Owner LP Account
		swapPool: web3.PublicKey,
		swapAuthority: web3.PublicKey,
		swapLpMint: web3.PublicKey,
		quoteTokenMint: web3.PublicKey,
		swapTokenAccountA: web3.PublicKey,
		swapTokenAccountB: web3.PublicKey,
		nftTokenProgram: web3.PublicKey,
		tokenProgram: web3.PublicKey,
		quoteTokenProgram: web3.PublicKey,
		swapProgram: web3.PublicKey,
		nftTokenMetadataProgram: web3.PublicKey,
		associatedTokenProgram: web3.PublicKey,
		systemProgram: web3.PublicKey,
		poolTokenAmount: BN,
		maximumTokenA: BN,
		maximumTokenB: BN,
	}): web3.TransactionInstruction {
		const dataLayout = struct([
			u64('poolTokenAmount'),
			u64('maximumTokenA'),
			u64('maximumTokenB'),
		]);

		const desc = this.ixDescriminator("add_liquidity")

		const buffer = Buffer.alloc(desc.length + dataLayout.span);
		const len = dataLayout.encode({
			poolTokenAmount: args.poolTokenAmount,
			maximumTokenA: args.maximumTokenA,
			maximumTokenB: args.maximumTokenB,
		}, buffer);
		const data = Buffer.concat([desc, buffer.slice(0, len)]);

		const keys = [
			{pubkey: args.owner, isSigner: true, isWritable: true},
			{pubkey: args.pool, isSigner: false, isWritable: false},
			{pubkey: args.nftMint, isSigner: false, isWritable: false},
			{pubkey: args.nftMetadata, isSigner: false, isWritable: false},
			{pubkey: args.collectionTokenMint, isSigner: false, isWritable: true},
			{pubkey: args.ownerTokenAccount, isSigner: false, isWritable: true},
			{pubkey: args.ownerQuoteAccount, isSigner: false, isWritable: true},
			{pubkey: args.poolTokenAccount, isSigner: false, isWritable: true},
			{pubkey: args.ownerNftAccount, isSigner: false, isWritable: true},
			{pubkey: args.poolNftAccount, isSigner: false, isWritable: true},
			{pubkey: args.ownerPoolAccount, isSigner: false, isWritable: true},
			{pubkey: args.swapPool, isSigner: false, isWritable: true},
			{pubkey: args.swapAuthority, isSigner: false, isWritable: true},
			{pubkey: args.swapLpMint, isSigner: false, isWritable: true},
			{pubkey: args.quoteTokenMint, isSigner: false, isWritable: false},
			{pubkey: args.swapTokenAccountA, isSigner: false, isWritable: true},
			{pubkey: args.swapTokenAccountB, isSigner: false, isWritable: true},
			{pubkey: args.nftTokenProgram, isSigner: false, isWritable: false},
			{pubkey: args.tokenProgram, isSigner: false, isWritable: false},
			{pubkey: args.quoteTokenProgram, isSigner: false, isWritable: false},
			{pubkey: args.swapProgram, isSigner: false, isWritable: false},
			{pubkey: args.nftTokenMetadataProgram, isSigner: false, isWritable: false},
			{pubkey: args.associatedTokenProgram, isSigner: false, isWritable: false},
			{pubkey: args.systemProgram, isSigner: false, isWritable: false},
		];

		return new web3.TransactionInstruction({
			keys,
			programId: this.programID,
			data,
		});
	}

	/**
	 * Remove liquidity in the form of a NFT from the pool
	 * @param owner
	 * @param collection
	 * @param nft
	 * @param maximumPoolTokens
	 */
	async removeLiquidity(owner: web3.PublicKey, collection: web3.PublicKey, nft: web3.PublicKey, maximumPoolTokens: number): Promise<web3.Transaction> {
		const market = await this.getMarket(collection, WSOL)
		if (!market)
			return Promise.reject("collection market not found")

		console.log("Market", JSON.parse(JSON.stringify(market)))

		const [collectionPool] = web3.PublicKey.findProgramAddressSync([collection.toBuffer()], this.programID);
		const [swapAuthority] = web3.PublicKey.findProgramAddressSync([market?.swapPool!.toBuffer()], SWAP_PROGRAM_ID);

		const pool = await this.swapClient.getPool(market?.swapPool!)
		const poolDetail = await this.swapClient.getPoolDetail(market?.swapPool!, pool.account, owner)

		const spotPrice = poolDetail?.tokenAccountB.tokenAmount.amount / poolDetail?.tokenAccountA.tokenAmount.amount;
		const tokenBAmount = Math.ceil(spotPrice * 100_000)

		console.log("NFTPool", {pool: JSON.parse(JSON.stringify(pool)), poolDetail, spotPrice, minOut: tokenBAmount / Math.pow(10, 9)})


		const totalLpSupply = poolDetail.mintLp?.supply; //Total Liq
		const yourLpTokens = poolDetail.userLP?.tokenAmount?.amount
		const yourShareOfTotalLiquidity = yourLpTokens / totalLpSupply;
		const totalTokenAInPool = poolDetail.tokenAccountA.tokenAmount?.amount; // total token A in the pool
		const totalTokenBInPool = poolDetail.tokenAccountB.tokenAmount?.amount; // total token B in the pool


		const pctNeeded = 100_000 / totalTokenAInPool;
		const poolTokensIn = Math.ceil(totalLpSupply * pctNeeded);
		const minTokenBOut = Math.floor(totalTokenBInPool * (poolTokensIn/totalLpSupply));


		console.log("poolDetail", {
			yourLpTokens,
			yourShareOfTotalLiquidity,
			totalLpSupply,
			totalTokenAInPool,
			totalTokenBInPool,
			poolTokensIn,
			minTokenBOut,
		})

		//TODO Fix maths
		// const minAmountOut = 0
		// const slipAmount = new BN(minAmountOut).mul(new BN(1)).div(new BN(10_000))
		// const minAmountOutSlip = new BN(minAmountOut).sub(slipAmount)


		const nftMetadata = this.token2022.getMetadataPDA(nft, PROGRAM_ID)
		const collectionTokenMint = pool.account.mintA

		const ownerQuoteAccount = getAssociatedTokenAddressSync(pool.account.mintB, owner, false) //TODO auto detect program_id

		const ownerTokenAccount = getAssociatedTokenAddressSync(collectionTokenMint, owner, false, TOKEN_2022_PROGRAM_ID)
		const poolTokenAccount = getAssociatedTokenAddressSync(collectionTokenMint, collectionPool, true, TOKEN_2022_PROGRAM_ID)

		const ownerPoolAccount = getAssociatedTokenAddressSync(pool.account.tokenPool, owner, false, TOKEN_2022_PROGRAM_ID) //LP Mint

		const ownerNftAccount = getAssociatedTokenAddressSync(nft, owner, false)
		const poolNftAccount = getAssociatedTokenAddressSync(nft, collectionPool, true)

		const txn = new web3.Transaction()
		txn.add(ComputeBudgetProgram.setComputeUnitLimit({units: 250000}))

		if (!await this.accountExists(ownerQuoteAccount))
			txn.add(createAssociatedTokenAccountInstruction(owner, ownerQuoteAccount, owner, pool.account.mintB))

		if (!await this.accountExists(ownerTokenAccount))
			txn.add(createAssociatedTokenAccountInstruction(owner, ownerTokenAccount, owner, collectionTokenMint, TOKEN_2022_PROGRAM_ID))

		if (!await this.accountExists(poolTokenAccount))
			txn.add(createAssociatedTokenAccountInstruction(owner, poolTokenAccount, collectionPool, collectionTokenMint, TOKEN_2022_PROGRAM_ID))


		txn.add(this.createRemoveLiquidityInstruction({
			owner: owner,
			pool: collectionPool,
			nftMint: nft,
			nftMetadata: nftMetadata,
			collectionTokenMint: collectionTokenMint,
			ownerTokenAccount: ownerTokenAccount,
			ownerQuoteAccount: ownerQuoteAccount,
			poolTokenAccount: poolTokenAccount,
			ownerNftAccount: ownerNftAccount,
			poolNftAccount: poolNftAccount,
			ownerPoolAccount: ownerPoolAccount,
			feeAccount: pool.account.feeAccount,
			swapPool: market.swapPool,
			swapAuthority: swapAuthority,
			swapLpMint: pool.account.tokenPool,
			quoteTokenMint: market.quoteMint,
			swapTokenAccountA: pool.account.tokenAccountA,
			swapTokenAccountB: pool.account.tokenAccountB,
			nftTokenProgram: TOKEN_PROGRAM_ID,
			tokenProgram: TOKEN_2022_PROGRAM_ID,
			quoteTokenProgram: TOKEN_PROGRAM_ID, //TODO Get correct ID
			swapProgram: SWAP_PROGRAM_ID,
			nftTokenMetadataProgram: PROGRAM_ID,
			associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
			systemProgram: web3.SystemProgram.programId,
			poolTokenAmount: new BN(poolTokensIn),
			minimumTokenA: new BN(100_000),
			minimumTokenB: new BN(minTokenBOut),
		}))

		return txn
	}

	createRemoveLiquidityInstruction(args: {
		owner: web3.PublicKey,
		pool: web3.PublicKey,
		nftMint: web3.PublicKey,
		nftMetadata: web3.PublicKey,
		collectionTokenMint: web3.PublicKey,
		ownerTokenAccount: web3.PublicKey,
		ownerQuoteAccount: web3.PublicKey,
		poolTokenAccount: web3.PublicKey,
		ownerNftAccount: web3.PublicKey,
		poolNftAccount: web3.PublicKey,
		ownerPoolAccount: web3.PublicKey, //Owner LP Account
		feeAccount: web3.PublicKey,
		swapPool: web3.PublicKey,
		swapAuthority: web3.PublicKey,
		swapLpMint: web3.PublicKey,
		quoteTokenMint: web3.PublicKey,
		swapTokenAccountA: web3.PublicKey,
		swapTokenAccountB: web3.PublicKey,
		nftTokenProgram: web3.PublicKey,
		tokenProgram: web3.PublicKey,
		quoteTokenProgram: web3.PublicKey,
		swapProgram: web3.PublicKey,
		nftTokenMetadataProgram: web3.PublicKey,
		associatedTokenProgram: web3.PublicKey,
		systemProgram: web3.PublicKey,
		poolTokenAmount: BN,
		minimumTokenA: BN,
		minimumTokenB: BN,
	}): web3.TransactionInstruction {
		const dataLayout = struct([
			u64('poolTokenAmount'),
			u64('minimumTokenA'),
			u64('minimumTokenB'),
		]);

		const desc = this.ixDescriminator("remove_liquidity")

		const buffer = Buffer.alloc(desc.length + dataLayout.span);
		const len = dataLayout.encode({
			poolTokenAmount: args.poolTokenAmount,
			minimumTokenA: args.minimumTokenA,
			minimumTokenB: args.minimumTokenB,
		}, buffer);
		const data = Buffer.concat([desc, buffer.slice(0, len)]);

		const keys = [
			{pubkey: args.owner, isSigner: true, isWritable: true},
			{pubkey: args.pool, isSigner: false, isWritable: false},
			{pubkey: args.nftMint, isSigner: false, isWritable: false},
			{pubkey: args.nftMetadata, isSigner: false, isWritable: false},
			{pubkey: args.collectionTokenMint, isSigner: false, isWritable: true},
			{pubkey: args.ownerTokenAccount, isSigner: false, isWritable: true},
			{pubkey: args.ownerQuoteAccount, isSigner: false, isWritable: true},
			{pubkey: args.poolTokenAccount, isSigner: false, isWritable: true},
			{pubkey: args.ownerNftAccount, isSigner: false, isWritable: true},
			{pubkey: args.poolNftAccount, isSigner: false, isWritable: true},
			{pubkey: args.ownerPoolAccount, isSigner: false, isWritable: true},
			{pubkey: args.swapPool, isSigner: false, isWritable: true},
			{pubkey: args.swapAuthority, isSigner: false, isWritable: true},
			{pubkey: args.swapLpMint, isSigner: false, isWritable: true},
			{pubkey: args.quoteTokenMint, isSigner: false, isWritable: false},
			{pubkey: args.swapTokenAccountA, isSigner: false, isWritable: true},
			{pubkey: args.swapTokenAccountB, isSigner: false, isWritable: true},
			{pubkey: args.feeAccount, isSigner: false, isWritable: true},
			{pubkey: args.nftTokenProgram, isSigner: false, isWritable: false},
			{pubkey: args.tokenProgram, isSigner: false, isWritable: false},
			{pubkey: args.quoteTokenProgram, isSigner: false, isWritable: false},
			{pubkey: args.swapProgram, isSigner: false, isWritable: false},
			{pubkey: args.nftTokenMetadataProgram, isSigner: false, isWritable: false},
			{pubkey: args.associatedTokenProgram, isSigner: false, isWritable: false},
			{pubkey: args.systemProgram, isSigner: false, isWritable: false},
		];

		return new web3.TransactionInstruction({
			keys,
			programId: this.programID,
			data,
		});
	}

	/**
	 * Exchange NFT for cTokens 1:10,000
	 * @param payer
	 * @param collection
	 * @param nft
	 * @param aToB
	 */
	async exchangeNFT(payer: web3.PublicKey, collection: web3.PublicKey, nft: web3.PublicKey, aToB: boolean = true): Promise<web3.Transaction> {
		const txn = new web3.Transaction()

		const [collectionPool] = web3.PublicKey.findProgramAddressSync([collection.toBuffer()], this.programID)
		const pool = await this.getPool(collectionPool)
		if (!pool) {
			return Promise.reject("collection pool not found")
		}


		const nftMetadata = this.token2022.getMetadataPDA(nft, PROGRAM_ID)
		const collectionTokenMint = pool.tokenMint


		const ownerTokenAccount = getAssociatedTokenAddressSync(collectionTokenMint, payer, false, TOKEN_2022_PROGRAM_ID)

		const ownerNftAccount = getAssociatedTokenAddressSync(nft, payer, false)
		const poolNftAccount = getAssociatedTokenAddressSync(nft, collectionPool, true)


		if (!await this.accountExists(ownerTokenAccount))
			txn.add(createAssociatedTokenAccountInstruction(payer, ownerTokenAccount, payer, collectionTokenMint, TOKEN_2022_PROGRAM_ID))

		txn.add(this.createExchangeNFTInstruction({
			payer: payer,
			pool: collectionPool,
			nftMint: nft,
			nftMetadata: nftMetadata,
			collectionTokenMint: collectionTokenMint,
			ownerTokenAccount: ownerTokenAccount,
			ownerNftAccount: ownerNftAccount,
			poolNftAccount: poolNftAccount,
			nftTokenProgram: TOKEN_PROGRAM_ID,
			tokenProgram: TOKEN_2022_PROGRAM_ID,
			nftTokenMetadataProgram: PROGRAM_ID,
			associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
			systemProgram: web3.SystemProgram.programId,
			aToB: aToB,
		}))

		return txn
	}


	/**
	 * Exchange NFT for SOL
	 *
	 * @param payer
	 * @param collection
	 * @param nft
	 * @param aToB
	 * @param amount
	 */
	async exchangeNFTSOL(payer: web3.PublicKey, collection: web3.PublicKey, nft: web3.PublicKey, aToB: boolean = true, amount: number): Promise<web3.Transaction> {
		const txn = new web3.Transaction()

		const [collectionPool] = web3.PublicKey.findProgramAddressSync([collection.toBuffer()], this.programID)
		const pool = await this.getPool(collectionPool)
		if (!pool) {
			return Promise.reject("collection pool not found")
		}

		const nftMetadata = this.token2022.getMetadataPDA(nft, PROGRAM_ID)
		const collectionTokenMint = pool.tokenMint

		const pools = await this.swapClient.getSwapPoolsSingle(collectionTokenMint)
		if (!pools || pools.length < 1) {
			return Promise.reject("collection swap pool not found")
		}

		const swapPool = pools[0]
		console.log("Swap pool", JSON.parse(JSON.stringify(swapPool)))

		const [swapAuthority] = web3.PublicKey.findProgramAddressSync([swapPool.pubkey.toBuffer()], SWAP_PROGRAM_ID);

		const swapLpMint = swapPool.account.tokenPool
		const quoteTokenMint = swapPool.account.mintB;
		const swapFeeAccount = swapPool.account.feeAccount
		const swapTokenAccountA = swapPool.account.tokenAccountA
		const swapTokenAccountB = swapPool.account.tokenAccountB

		const ownerTokenAccount = getAssociatedTokenAddressSync(collectionTokenMint, payer, false, TOKEN_2022_PROGRAM_ID)
		const ownerSolAccount = getAssociatedTokenAddressSync(swapPool.account.mintB, payer, false, TOKEN_PROGRAM_ID)

		const ownerNftAccount = getAssociatedTokenAddressSync(nft, payer, false)
		const poolNftAccount = getAssociatedTokenAddressSync(nft, collectionPool, true)

		if (!await this.accountExists(ownerSolAccount))
			txn.add(createAssociatedTokenAccountInstruction(payer, ownerSolAccount, payer, swapPool.account.mintB, TOKEN_PROGRAM_ID))

		txn.add(ComputeBudgetProgram.setComputeUnitLimit({units: 250000}))
		txn.add(this.createExchangeNFTSOLInstruction({
			payer: payer,
			pool: collectionPool,
			nftMint: nft,
			nftMetadata: nftMetadata,
			collectionTokenMint: collectionTokenMint,
			ownerTokenAccount: ownerTokenAccount,
			ownerNftAccount: ownerNftAccount,
			poolNftAccount: poolNftAccount,
			swapPool: swapPool.pubkey,
			swapAuthority: swapAuthority,
			swapLpMint: swapLpMint,
			swapFeeAccount: swapFeeAccount,
			quoteTokenMint: quoteTokenMint,
			swapTokenAccountA: swapTokenAccountA,
			swapTokenAccountB: swapTokenAccountB,
			ownerSolAccount: ownerSolAccount,
			nftTokenProgram: TOKEN_PROGRAM_ID,
			tokenProgram: TOKEN_2022_PROGRAM_ID,
			swapProgram: SWAP_PROGRAM_ID,
			nftTokenMetadataProgram: PROGRAM_ID,
			associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
			systemProgram: web3.SystemProgram.programId,
			aToB: aToB,
			amountIn: new BN(amount),
		}))

		return txn
	}

	async createMarket(payer: web3.PublicKey, collection: web3.PublicKey, nft: web3.PublicKey, collectionTokenMint: web3.PublicKey, startPrice: number) {
		const poolTransaction = new web3.Transaction()

		// const quoteMint = MSOL
		const quoteMint = WSOL

		const ix = await this._createMarket(payer, nft, collection, collectionTokenMint, quoteMint, TOKEN_PROGRAM_ID, new BN(startPrice))
		poolTransaction.add(ix.txn)
		return {transactions: [poolTransaction], signers: [ix.signers]}
	}

	async _createMarket(payer: web3.PublicKey, nft: web3.PublicKey, collection: web3.PublicKey, collectionTokenMint: web3.PublicKey, quoteMint: web3.PublicKey, quoteTokenProgram: web3.PublicKey, startPrice: BN): Promise<{ txn: web3.Transaction, signers: web3.Keypair[] }> {
		const transaction = new web3.Transaction()

		const [pool] = web3.PublicKey.findProgramAddressSync([collection.toBuffer()], this.programID);
		const swapPool = web3.Keypair.generate();
		const poolLpMint = web3.Keypair.generate();

		const [swapAuthority] = web3.PublicKey.findProgramAddressSync([swapPool.publicKey.toBuffer()], SWAP_PROGRAM_ID)

		const ownerAccountA = getAssociatedTokenAddressSync(collectionTokenMint, payer, false, TOKEN_2022_PROGRAM_ID)
		const ownerAccountB = getAssociatedTokenAddressSync(quoteMint, payer, false, TOKEN_PROGRAM_ID)

		const tokenAccountA = getAssociatedTokenAddressSync(collectionTokenMint, swapAuthority, true, TOKEN_2022_PROGRAM_ID)
		const tokenAccountB = getAssociatedTokenAddressSync(quoteMint, swapAuthority, true, TOKEN_PROGRAM_ID)

		const feeAccount = getAssociatedTokenAddressSync(poolLpMint.publicKey, SWAP_PROGRAM_OWNER_FEE_ADDRESS, false, TOKEN_2022_PROGRAM_ID)
		const poolLpAccount = getAssociatedTokenAddressSync(poolLpMint.publicKey, payer, false, TOKEN_2022_PROGRAM_ID)


		const nftMetadata = this.token2022.getMetadataPDA(nft, PROGRAM_ID)
		const [market, bump] = web3.PublicKey.findProgramAddressSync([collection.toBuffer(), quoteMint.toBuffer()], this.programID)

		const ownerNftAccount = getAssociatedTokenAddressSync(nft, payer, false, TOKEN_PROGRAM_ID)
		const poolNftAccount = getAssociatedTokenAddressSync(nft, pool, true, TOKEN_PROGRAM_ID)

		//TODO Remove?
		// transaction.add(
		// 	web3.SystemProgram.createAccount({
		// 		fromPubkey: payer,
		// 		newAccountPubkey: poolLpMint.publicKey,
		// 		space: MINT_SIZE,
		// 		lamports: await getMinimumBalanceForRentExemptMint(this.connection),
		// 		programId: TOKEN_2022_PROGRAM_ID,
		// 	}),
		// 	createInitializeMint2Instruction(poolLpMint.publicKey, 2, swapAuthority, null, TOKEN_2022_PROGRAM_ID)
		// )


		if (!await this.accountExists(ownerAccountA))
			transaction.add(createAssociatedTokenAccountInstruction(payer, ownerAccountA, payer, collectionTokenMint, TOKEN_2022_PROGRAM_ID))

		transaction.add(createAssociatedTokenAccountInstruction(payer, tokenAccountA, swapAuthority, collectionTokenMint, TOKEN_2022_PROGRAM_ID))
		transaction.add(createAssociatedTokenAccountInstruction(payer, tokenAccountB, swapAuthority, quoteMint, TOKEN_PROGRAM_ID))

		// transaction.add(createAssociatedTokenAccountInstruction(payer, poolLpAccount, payer, poolLpMint.publicKey, TOKEN_2022_PROGRAM_ID))
		// transaction.add(createAssociatedTokenAccountInstruction(payer, feeAccount, SWAP_PROGRAM_OWNER_FEE_ADDRESS, poolLpMint.publicKey, TOKEN_2022_PROGRAM_ID))

		transaction.add(this.createMarketInstruction({
			owner: payer,
			pool: pool,
			market: market,
			nftMint: nft,
			nftMetadata: nftMetadata,
			ownerNftAccount: ownerNftAccount,
			poolNftAccount: poolNftAccount,
			collectionTokenMint: collectionTokenMint,
			collection: collection,
			swapPool: swapPool.publicKey,
			swapAuthority: swapAuthority,
			quoteMint: quoteMint,
			ownerAccountA: ownerAccountA,
			ownerAccountB: ownerAccountB,
			tokenAccountA: tokenAccountA,
			tokenAccountB: tokenAccountB,
			poolLpMint: poolLpMint.publicKey,
			feeAccountOwner: SWAP_PROGRAM_OWNER_FEE_ADDRESS,
			feeAccount: feeAccount,
			poolLpAccount: poolLpAccount,
			tokenV1Program: TOKEN_PROGRAM_ID,
			tokenProgram: TOKEN_2022_PROGRAM_ID,
			swapProgram: SWAP_PROGRAM_ID,
			tokenMetadataProgram: METADATA_2022_PROGRAM_ID,
			systemProgram: web3.SystemProgram.programId,
			associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
			bump,
			startPrice
		}))

		return {
			txn: transaction,
			signers: [
				swapPool,
				poolLpMint
			]
		}
	}

	createMarketInstruction(args: {
		owner: web3.PublicKey,
		pool: web3.PublicKey,
		market: web3.PublicKey,
		nftMint: web3.PublicKey,
		nftMetadata: web3.PublicKey,
		ownerNftAccount: web3.PublicKey,
		poolNftAccount: web3.PublicKey,
		collectionTokenMint: web3.PublicKey,
		collection: web3.PublicKey,
		swapPool: web3.PublicKey,
		swapAuthority: web3.PublicKey,
		quoteMint: web3.PublicKey,
		ownerAccountA: web3.PublicKey,
		ownerAccountB: web3.PublicKey,
		tokenAccountA: web3.PublicKey,
		tokenAccountB: web3.PublicKey,
		poolLpMint: web3.PublicKey,
		feeAccountOwner: web3.PublicKey,
		feeAccount: web3.PublicKey,
		poolLpAccount: web3.PublicKey,
		tokenV1Program: web3.PublicKey,
		tokenProgram: web3.PublicKey,
		swapProgram: web3.PublicKey,
		tokenMetadataProgram: web3.PublicKey,
		systemProgram: web3.PublicKey,
		associatedTokenProgram: web3.PublicKey,
		bump: number,
		startPrice: BN
	}): web3.TransactionInstruction {
		const dataLayout = struct([
			u8('bump'),
			u64('startPrice')
		]);

		const desc = this.ixDescriminator("create_market")
		const buffer = Buffer.alloc(desc.length + dataLayout.span);
		const len = dataLayout.encode({
			bump: args.bump,
			startPrice: args.startPrice,
		}, buffer);
		const data = Buffer.concat([desc, buffer.slice(0, len)]);

		return new web3.TransactionInstruction({
			keys: [
				{pubkey: args.owner, isWritable: true, isSigner: true},
				{pubkey: args.pool, isWritable: true, isSigner: false},
				{pubkey: args.market, isWritable: true, isSigner: false},
				{pubkey: args.nftMint, isWritable: false, isSigner: false},
				{pubkey: args.nftMetadata, isWritable: false, isSigner: false},
				{pubkey: args.ownerNftAccount, isWritable: true, isSigner: false},
				{pubkey: args.poolNftAccount, isWritable: true, isSigner: false},
				{pubkey: args.collectionTokenMint, isWritable: true, isSigner: false},
				{pubkey: args.collection, isWritable: false, isSigner: false},

				{pubkey: args.swapPool, isWritable: true, isSigner: true},
				{pubkey: args.swapAuthority, isWritable: true, isSigner: false},
				{pubkey: args.quoteMint, isWritable: false, isSigner: false},
				{pubkey: args.ownerAccountA, isWritable: true, isSigner: false},
				{pubkey: args.ownerAccountB, isWritable: true, isSigner: false},
				{pubkey: args.tokenAccountA, isWritable: true, isSigner: false},
				{pubkey: args.tokenAccountB, isWritable: true, isSigner: false},
				{pubkey: args.poolLpMint, isWritable: true, isSigner: true},
				{pubkey: args.feeAccountOwner, isWritable: false, isSigner: false},
				{pubkey: args.feeAccount, isWritable: true, isSigner: false},
				{pubkey: args.poolLpAccount, isWritable: true, isSigner: false},

				{pubkey: args.tokenV1Program, isWritable: false, isSigner: false},
				{pubkey: args.tokenProgram, isWritable: false, isSigner: false},
				{pubkey: args.swapProgram, isWritable: false, isSigner: false},
				{pubkey: args.tokenMetadataProgram, isWritable: false, isSigner: false},
				{pubkey: args.systemProgram, isWritable: false, isSigner: false},
				{pubkey: args.associatedTokenProgram, isWritable: false, isSigner: false},
			],
			programId: this.programID,
			data,
		});
	}

	async createLocker(
		payer: web3.PublicKey,
		collection: web3.PublicKey,
		nft: web3.PublicKey,
		mint: web3.Keypair = web3.Keypair.generate()
	) {
		const transaction = new web3.Transaction()

		const collectionTokenMint = mint.publicKey;

		const [pool, bump] = web3.PublicKey.findProgramAddressSync([collection.toBuffer()], this.programID);

		const nft_locker = getAssociatedTokenAddressSync(nft, pool, true, TOKEN_PROGRAM_ID)

		const nftMetadata = this.token2022.getMetadataPDA(nft, PROGRAM_ID)
		const collectionMetadata = this.token2022.getMetadataPDA(collection, PROGRAM_ID)
		const collectionTokenMetadata = this.token2022.getMetadataPDA(collectionTokenMint, METADATA_2022_PROGRAM_ID)

		const metadataUpdateAuthority = payer

		const nft_src = getAssociatedTokenAddressSync(nft, payer, false, TOKEN_PROGRAM_ID)
		const token_dst = getAssociatedTokenAddressSync(collectionTokenMint, payer, false, TOKEN_2022_PROGRAM_ID)

		transaction.add(this.createLockerInstruction(
			pool,
			payer,
			nft,
			nft_src,
			nftMetadata,
			token_dst,
			nft_locker,
			collection,
			collectionMetadata,
			collectionTokenMint,
			collectionTokenMetadata,
			metadataUpdateAuthority,
			TOKEN_PROGRAM_ID,
			TOKEN_2022_PROGRAM_ID,
			PROGRAM_ID, //NFT Metadata
			METADATA_2022_PROGRAM_ID, //Collection token metadata
			bump,
		))

		return {
			transactions: [transaction],
			signers: [[mint]]
		}
	}

	createLockerInstruction(
		pool: web3.PublicKey,
		payer: web3.PublicKey,
		nft: web3.PublicKey,
		nftSrc: web3.PublicKey,
		nftMetadata: web3.PublicKey,
		tokenDst: web3.PublicKey,
		nftLocker: web3.PublicKey,
		collection: web3.PublicKey,
		collectionMetadata: web3.PublicKey,
		collectionTokenMint: web3.PublicKey,
		collectionTokenMetadata: web3.PublicKey,
		metadataUpdateAuthority: web3.PublicKey,
		nftTokenProgram: web3.PublicKey,
		tokenProgram: web3.PublicKey,
		nftTokenMetadataProgram: web3.PublicKey,
		tokenMetadataProgram: web3.PublicKey,
		bump: number
	): web3.TransactionInstruction {
		const dataLayout = struct([
			u8('bump'),
		]);

		const desc = this.ixDescriminator("create_pool")
		const buffer = Buffer.alloc(desc.length + dataLayout.span);
		const len = dataLayout.encode({
			bump: bump
		}, buffer);
		const data = Buffer.concat([desc, buffer.slice(0, len)]);

		return new web3.TransactionInstruction({
			keys: [
				{pubkey: pool, isWritable: true, isSigner: false},
				{pubkey: payer, isWritable: true, isSigner: true},
				{pubkey: collection, isWritable: false, isSigner: false},
				{pubkey: collectionMetadata, isWritable: false, isSigner: false},
				{pubkey: collectionTokenMint, isWritable: true, isSigner: true},
				{pubkey: collectionTokenMetadata, isWritable: true, isSigner: false},
				{pubkey: metadataUpdateAuthority, isWritable: false, isSigner: false},
				{pubkey: tokenProgram, isWritable: false, isSigner: false},
				{pubkey: nftTokenMetadataProgram, isWritable: false, isSigner: false},
				{pubkey: tokenMetadataProgram, isWritable: false, isSigner: false},
				{pubkey: web3.SystemProgram.programId, isWritable: false, isSigner: false},
				{pubkey: ASSOCIATED_TOKEN_PROGRAM_ID, isWritable: false, isSigner: false},
			],
			programId: this.programID,
			data,
		});
	}

	/**
	 * Create a NFT token mint
	 * @param payer
	 * @param collection
	 * @param tokenMint
	 * @param decimals
	 * @param mint_authority
	 * @param priorityFee
	 * @param metadataCID
	 */
	async _getCreateMintTransaction(payer: web3.PublicKey, collection: web3.PublicKey, tokenMint: web3.PublicKey, decimals = 0, mint_authority: web3.PublicKey, priorityFee: number, metadataCID: string): Promise<web3.Transaction> {
		const metaPk = this.token2022.getMetadataPDA(collection, PROGRAM_ID) //Get original metadata

		const collectionInfo = await Metadata.fromAccountAddress(this.connection, metaPk)

		const mintName = collectionInfo.data.name.trim().split(/\r|\r\n|\n/)[0]
		const mintSymbol = collectionInfo.data.symbol.trim().split(/\r|\r\n|\n/)[0]
		const metadataUri = collectionInfo.data.uri.trim().split(/\r|\r\n|\n/)[0]

		const config = new CreateMint(mintName, mintSymbol, metadataUri, BigInt(0), mint_authority, null, decimals, false)
		config.addExtension(ExtensionType.TransferFeeConfig, {
			maxFee: 0,
			feeBasisPoints: collectionInfo.data.sellerFeeBasisPoints,
			withdraw_withheld_authority: mint_authority,
			transfer_fee_config_authority: payer
		})

		return await this.token2022.getCreateMintTransaction(payer, tokenMint, config, priorityFee, metadataCID)
	}

	createExchangeNFTInstruction(args: {
		payer: web3.PublicKey,
		pool: web3.PublicKey,
		nftMint: web3.PublicKey,
		nftMetadata: web3.PublicKey,
		collectionTokenMint: web3.PublicKey,
		ownerTokenAccount: web3.PublicKey,
		ownerNftAccount: web3.PublicKey,
		poolNftAccount: web3.PublicKey,
		nftTokenProgram: web3.PublicKey,
		tokenProgram: web3.PublicKey,
		nftTokenMetadataProgram: web3.PublicKey,
		associatedTokenProgram: web3.PublicKey,
		systemProgram: web3.PublicKey,
		aToB: boolean
	}): web3.TransactionInstruction {
		const dataLayout = struct([
			bool('aToB'),
		]);

		const desc = this.ixDescriminator("exchange_nft")

		const buffer = Buffer.alloc(desc.length + dataLayout.span);
		const len = dataLayout.encode({
			aToB: args.aToB
		}, buffer);
		const data = Buffer.concat([desc, buffer.slice(0, len)]);

		const keys = [
			{pubkey: args.payer, isSigner: false, isWritable: true},
			{pubkey: args.pool, isSigner: false, isWritable: true},
			{pubkey: args.nftMint, isSigner: false, isWritable: false},
			{pubkey: args.nftMetadata, isSigner: false, isWritable: false},
			{pubkey: args.collectionTokenMint, isSigner: false, isWritable: true},
			{pubkey: args.ownerTokenAccount, isSigner: false, isWritable: true},
			{pubkey: args.ownerNftAccount, isSigner: false, isWritable: true},
			{pubkey: args.poolNftAccount, isSigner: false, isWritable: true},
			{pubkey: args.nftTokenProgram, isSigner: false, isWritable: false},
			{pubkey: args.tokenProgram, isSigner: false, isWritable: false},
			{pubkey: args.nftTokenMetadataProgram, isSigner: false, isWritable: false},
			{pubkey: args.associatedTokenProgram, isSigner: false, isWritable: false},
			{pubkey: args.systemProgram, isSigner: false, isWritable: false},
		];

		return new web3.TransactionInstruction({
			keys,
			programId: this.programID,
			data,
		});

		//TODO Implement above
		return new web3.TransactionInstruction({
			keys: [],
			programId: this.programID
		})
	}

	createExchangeNFTSOLInstruction(args: {
		payer: web3.PublicKey,
		pool: web3.PublicKey,
		nftMint: web3.PublicKey,
		nftMetadata: web3.PublicKey,
		collectionTokenMint: web3.PublicKey,
		ownerTokenAccount: web3.PublicKey,
		ownerNftAccount: web3.PublicKey,
		poolNftAccount: web3.PublicKey,
		swapPool: web3.PublicKey,
		swapAuthority: web3.PublicKey,
		swapLpMint: web3.PublicKey,
		swapFeeAccount: web3.PublicKey,
		quoteTokenMint: web3.PublicKey,
		swapTokenAccountA: web3.PublicKey,
		swapTokenAccountB: web3.PublicKey,
		ownerSolAccount: web3.PublicKey,
		nftTokenProgram: web3.PublicKey,
		tokenProgram: web3.PublicKey,
		swapProgram: web3.PublicKey,
		nftTokenMetadataProgram: web3.PublicKey,
		associatedTokenProgram: web3.PublicKey,
		systemProgram: web3.PublicKey,
		amountIn: BN,
		aToB: boolean
	}): web3.TransactionInstruction {
		const dataLayout = struct([
			bool('aToB'),
			u64('amount_in'),
		]);

		const desc = this.ixDescriminator("exchange_sol")

		const buffer = Buffer.alloc(desc.length + dataLayout.span);
		const len = dataLayout.encode({
			aToB: args.aToB,
			amount_in: args.amountIn
		}, buffer);
		const data = Buffer.concat([desc, buffer.slice(0, len)]);

		const keys = [
			{pubkey: args.payer, isSigner: true, isWritable: true},
			{pubkey: args.pool, isSigner: false, isWritable: false},
			{pubkey: args.nftMint, isSigner: false, isWritable: false},
			{pubkey: args.nftMetadata, isSigner: false, isWritable: false},
			{pubkey: args.collectionTokenMint, isSigner: false, isWritable: true},
			{pubkey: args.ownerTokenAccount, isSigner: false, isWritable: true},
			{pubkey: args.ownerNftAccount, isSigner: false, isWritable: true},
			{pubkey: args.poolNftAccount, isSigner: false, isWritable: true},
			{pubkey: args.swapPool, isSigner: false, isWritable: false},
			{pubkey: args.swapAuthority, isSigner: false, isWritable: false},
			{pubkey: args.swapLpMint, isSigner: false, isWritable: true},
			{pubkey: args.quoteTokenMint, isSigner: false, isWritable: false},
			{pubkey: args.swapFeeAccount, isSigner: false, isWritable: true},
			{pubkey: args.swapTokenAccountA, isSigner: false, isWritable: true},
			{pubkey: args.swapTokenAccountB, isSigner: false, isWritable: true},
			{pubkey: args.ownerSolAccount, isSigner: false, isWritable: true},

			{pubkey: args.nftTokenProgram, isSigner: false, isWritable: false},
			{pubkey: args.tokenProgram, isSigner: false, isWritable: false},
			{pubkey: args.swapProgram, isSigner: false, isWritable: false},
			{pubkey: args.nftTokenMetadataProgram, isSigner: false, isWritable: false},
			{pubkey: args.associatedTokenProgram, isSigner: false, isWritable: false},
			{pubkey: args.systemProgram, isSigner: false, isWritable: false},
		];

		return new web3.TransactionInstruction({
			keys,
			programId: this.programID,
			data,
		});
	}

	async claimRoyalties(
		payer: web3.PublicKey,
		collection: web3.PublicKey,
	) {
		//Get pool
		const [collectionPool] = web3.PublicKey.findProgramAddressSync([collection.toBuffer()], this.programID)
		const pool = await this.getPool(collectionPool)
		if (!pool) {
			return Promise.reject("collection pool not found")
		}

		//Get collection metadata
		const collectionMetadata = this.token2022.getMetadataPDA(collection, PROGRAM_ID)

		const metadata = await Metadata.fromAccountAddress(this.connection, collectionMetadata)
		if (!metadata) {
			return Promise.reject("collection metadata not found")
		}

		const poolTokenAccount = getAssociatedTokenAddressSync(pool.tokenMint, collectionPool, true, TOKEN_2022_PROGRAM_ID)

		const creators = metadata?.data?.creators || [];
		const creatorAccounts = [];

		for (let i = 0; i < creators.length; i++) {
			if (!creators[i].verified)
				continue
			creatorAccounts.push(creators[i].address)
		}

		const txn = new web3.Transaction()

		if (!await this.accountExists(poolTokenAccount))
			txn.add(createAssociatedTokenAccountInstruction(payer, poolTokenAccount, collectionPool, pool.tokenMint, TOKEN_2022_PROGRAM_ID))

		txn.add(this.createClaimRoyaltiesInstruction({
			collectionMetadata: collectionMetadata,
			collectionTokenMint: pool.tokenMint,
			payer: payer,
			pool: collectionPool,
			poolTokenAccount: poolTokenAccount,
			tokenProgram: TOKEN_2022_PROGRAM_ID,
			creators: creatorAccounts,
		}))

		return {
			transactions: [txn],
			signers: []
		}
	}


	createClaimRoyaltiesInstruction(args: {
		payer: web3.PublicKey,
		pool: web3.PublicKey,
		collectionMetadata: web3.PublicKey,
		collectionTokenMint: web3.PublicKey,
		poolTokenAccount: web3.PublicKey,
		tokenProgram: web3.PublicKey,
		creators: web3.PublicKey[]
	}): web3.TransactionInstruction {
		const desc = this.ixDescriminator("claim_royalties")
		const data = Buffer.concat([desc]);

		const keys = [
			{pubkey: args.payer, isSigner: true, isWritable: false},
			{pubkey: args.pool, isSigner: false, isWritable: false},
			{pubkey: args.collectionMetadata, isSigner: false, isWritable: false},
			{pubkey: args.collectionTokenMint, isSigner: false, isWritable: true},
			{pubkey: args.poolTokenAccount, isSigner: false, isWritable: true},
			{pubkey: args.tokenProgram, isSigner: false, isWritable: false},
		];

		for (let i = 0; i < args.creators.length; i++)
			keys.push(...[
				{pubkey: args.creators[i], isSigner: false, isWritable: false},
				{pubkey: getAssociatedTokenAddressSync(args.collectionTokenMint, args.creators[i], false, args.tokenProgram), isSigner: false, isWritable: true},
			])

		return new web3.TransactionInstruction({
			keys,
			programId: this.programID,
			data,
		});
	}

	/**
	 * Convert to instruction descriminator
	 * Should be SNAKE_CASE (_)
	 * @param methodName
	 */
	ixDescriminator(methodName: string) {
		return Buffer.from(sha256.digest(`global:${methodName}`)).slice(0, 8)
	}


	sanitizeTokenMeta(tokenData: Metadata) {
		return {
			...tokenData,
			data: {
				...tokenData?.data,
				name: this.sanitizeMetaStrings(tokenData?.data?.name || ''),
				symbol: this.sanitizeMetaStrings(tokenData?.data?.symbol || ''),
				uri: this.sanitizeMetaStrings(tokenData?.data?.uri || ''),
			},
		}
	}

	/**
	 * Remove all empty space, new line, etc. symbols
	 *
	 * @param metaString
	 */
	sanitizeMetaStrings(metaString: string) {
		return metaString.replace(/\0/g, "");
	}

	async accountExists(account: web3.PublicKey) {
		const acc = await this.connection.getAccountInfo(account, "confirmed").catch(e => {
		})
		return !!acc;
	}
}