import {web3} from "@project-serum/anchor";
import {Metadata, PROGRAM_ID} from "@metaplex-foundation/mpl-token-metadata";
import {TOKEN_PROGRAM_ID} from "@solana/spl-token";

export type Options = {
	publicAddress: web3.PublicKey;

	connection: web3.Connection;
};

export const getParsedNftAccountsByOwner = async ({publicAddress, connection}: Options) => {

	// Get all accounts owned by user
	// and created by SPL Token Program
	// this will include all NFTs, Coins, Tokens, etc.
	const {value: splAccounts} = await connection.getParsedTokenAccountsByOwner(
		new web3.PublicKey(publicAddress),
		{
			programId: new web3.PublicKey(TOKEN_PROGRAM_ID),
		},
		"confirmed"
	);

	// We assume NFT is SPL token with decimals === 0 and amount at least 1
	// At this point we filter out other SPL tokens, like coins e.g.
	// Unfortunately, this method will also remove NFTы created before Metaplex NFT Standard
	// like Solarians e.g., so you need to check wallets for them in separate call if you wish
	const nftAccounts = splAccounts
		.filter((t) => {
			const amount = t.account?.data?.parsed?.info?.tokenAmount?.uiAmount;
			const decimals = t.account?.data?.parsed?.info?.tokenAmount?.decimals;
			return decimals === 0 && amount >= 1;
		})
		.map((t) => {
			const address = t.account?.data?.parsed?.info?.mint;
			return new web3.PublicKey(address);
		});

	// if user have tons of NFTs return first N
	const accountsSlice = nftAccounts?.slice(0, 5000);

	// Get Addresses of Metadata Account assosiated with Mint Token
	// This info can be deterministically calculated by Associated Token Program
	// available in @solana/web3.js
	const metadataAcountsAddressPromises = await Promise.allSettled(
		accountsSlice.map(getSolanaMetadataAddress)
	);

	const metadataAccounts = metadataAcountsAddressPromises
		.filter(onlySuccessfullPromises)
		.map((p) => (p as PromiseFulfilledResult<web3.PublicKey>).value);

	// Fetch Found Metadata Account data by chunks
	const metaAccountsRawPromises: PromiseSettledResult<
		(web3.AccountInfo<Buffer | web3.ParsedAccountData> | null)[]
	>[] = await Promise.allSettled(
		chunks(metadataAccounts, 99).map((chunk) =>
			connection.getMultipleAccountsInfo(chunk as web3.PublicKey[])
		)
	);

	const accountsRawMeta = metaAccountsRawPromises
		.filter(({status}) => status === "fulfilled")
		.flatMap((p) => (p as PromiseFulfilledResult<unknown>).value);

	// There is no reason to continue processing
	// if Mints doesn't have associated metadata account. just return []
	if (!accountsRawMeta?.length || accountsRawMeta?.length === 0) {
		return [];
	}

	// Decode data from Buffer to readable objects
	const accountsDecodedMeta = await Promise.allSettled(
		accountsRawMeta.map((accountInfo) => {
			const [metadata] = Metadata.fromAccountInfo(accountInfo as web3.AccountInfo<Buffer>)
			// decodeTokenMetadata((accountInfo as web3.AccountInfo<Buffer>)?.data)
			return metadata;
		})
	);

	return accountsDecodedMeta
		.filter(onlySuccessfullPromises)
		.filter(onlyNftsWithMetadata)
		.map((p) => {
			const {value} = p as PromiseFulfilledResult<Metadata>;
			return sanitizeTokenMeta(value);
		})
		.map((token) => token);
};

const onlySuccessfullPromises = (
	result: PromiseSettledResult<unknown>
): boolean => result && result.status === "fulfilled";

// Remove any NFT Metadata Account which doesn't have uri field
// We can assume such NFTs are broken or invalid.
const onlyNftsWithMetadata = (t: PromiseSettledResult<Metadata>) => {
	const uri = (
		t as PromiseFulfilledResult<Metadata>
	).value.data?.uri?.replace?.(/\0/g, "");
	return uri !== "" && uri !== undefined;
};

function getSolanaMetadataAddress(tokenMint: web3.PublicKey) {
	const metaProgamPublicKey = new web3.PublicKey(PROGRAM_ID);
	return (
		web3.PublicKey.findProgramAddressSync(
			[Buffer.from("metadata"), metaProgamPublicKey.toBuffer(), tokenMint.toBuffer()],
			metaProgamPublicKey
		)
	)[0];
}

function chunks(array: web3.PublicKey[] = [], size = 100) {
	const chunks = []

	for (let i = 0; i < array.length; i += size) {
		chunks.push(array.slice(i, i + size))
	}

	return chunks
}

const sanitizeTokenMeta = (tokenData: Metadata) => ({
	...tokenData,
	data: {
		...tokenData?.data,
		name: sanitizeMetaStrings(tokenData?.data?.name),
		symbol: sanitizeMetaStrings(tokenData?.data?.symbol),
		uri: sanitizeMetaStrings(tokenData?.data?.uri),
	},
});

// Remove all empty space, new line, etc. symbols
// In some reason such symbols parsed back from Buffer looks weird
// like "\x0000" instead of usual spaces.
export const sanitizeMetaStrings = (metaString: string) =>
	metaString.replace(/\0/g, "");
