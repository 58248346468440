import {web3} from "@project-serum/anchor";
import {MARKET_STATE_LAYOUT_V3} from "@/api/layouts/serum";
import {OPENBOOK_PROGRAM_ID} from "@/api/token_swap/constants";

export const MARKET_ACCOUNT_FLAGS_B58_ENCODED = "W723RTUpoZ";

export default class Openbook {

	connection: web3.Connection

	programID: web3.PublicKey = OPENBOOK_PROGRAM_ID

	constructor(connection: web3.Connection) {
		this.connection = connection
	}

	async getMarkets() {
		const resp = await this.connection.getProgramAccounts(this.programID, {
			filters: [
				{
					memcmp: {
						offset: 5,
						bytes: MARKET_ACCOUNT_FLAGS_B58_ENCODED,
					}
				}
			]
		})

		return resp.map((m) => {
			return MARKET_STATE_LAYOUT_V3.decode(m.account.data)
		})
	}
}