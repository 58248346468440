import {web3} from "@project-serum/anchor";
import {createWithdrawWithheldTokensFromMintInstruction, getAssociatedTokenAddressSync, TOKEN_2022_PROGRAM_ID} from "@solana/spl-token";

export class FeeManagerAutomated {

	baseURI = "https://api.fluxbeam.xyz/v1"

	FEE_ACCOUNTS = [
		new web3.PublicKey("FEE1M4bRtos7Yi8cni9s6zpxDfZTSsARwrhqMJMLLKhv"),
		// new web3.PublicKey("FEE2qEfVjE64bdT9SXRa4ALvm4tFcwg7WE8ArQDuTjuD"),
		// new web3.PublicKey("FEE3veNwA943NP38BB968i3Zo1Mjxtn9bkHN8eK8UKHS"),
		// new web3.PublicKey("FEE4hmPcfXNTgscRrqpjnQQiNjdQN4XGVVZQqHgFYcNT"),
		// new web3.PublicKey("FEE5sDtT4N7AsGnsBNxB9EUEMTnRoJqZ56FZubsDKTxm"),
		// new web3.PublicKey("FEE6Ytka6NhFKNN9mJrGGGomSMs1Dk7GDXEwdjoiwt6t"),
		// new web3.PublicKey("FEE7HqiD7CCv9bYrWhfFou8naYdhZU78C6KdWV8kLouY"),
		// new web3.PublicKey("FEE8ZTYvsC3CLe8YnkN4vXikxnUcecmDB5D8ShXAAqPW"),
		// new web3.PublicKey("FEE9Z48Pdm7Dt1pr5kCZ1gwt6hWX5Kmohk45SBJHXmZX"),
	]

	async clusterStatus() {
		const resp = await fetch(this.uri(`status`))
		if (resp.status != 200)
			throw resp.statusText

		return await resp.json()
	}

	async job(pk: string) {
		return (await fetch(this.uri(`jobs/${pk}`))).json()
	}

	async history(mint: web3.PublicKey) {
		return (await fetch(this.uri(`history/${mint}`))).json()
	}

	async restart(pk) {
		return (await fetch(this.uri(`jobs/${pk}/restart`), {
			method: "PUT",
		})).json()
	}

	async quote(pk: web3.PublicKey, mint: web3.PublicKey) {
		return (await fetch(this.uri(`jobs/quote`), {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				payer: pk,
				mint: mint,
				pipeline: "Fee Manager"
			})
		})).json()
	}

	async submit(quote, hash, sig) {
		const resp = await fetch(this.uri(`jobs`), {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				quote: quote,
				hash: hash,
				signature: sig
			})
		})
		return resp.json()
	}

	uri(endpoint: string) {
		return `${this.baseURI}/workflow/${endpoint}`
	}

	feeAccount(): web3.PublicKey {
		return this.FEE_ACCOUNTS[Math.floor(Math.random() * this.FEE_ACCOUNTS.length)];
	}

	buildFeeTransaction(quoteReq, priorityFee) {
		const quote = quoteReq.quote
		console.log("buildFeeTransaction", {quoteReq, quote, priorityFee})
		const transaction = new web3.Transaction()
		const unitLimit = 160_000
		const unitPrice = Math.floor(priorityFee / unitLimit)

		transaction.add(
			web3.ComputeBudgetProgram.setComputeUnitLimit({units: unitLimit}),
			web3.ComputeBudgetProgram.setComputeUnitPrice({microLamports: unitPrice}),
			web3.SystemProgram.transfer({fromPubkey: new web3.PublicKey(quote.payer), toPubkey: this.feeAccount(), lamports: quote.fee}),
			new web3.TransactionInstruction({
				keys: [],
				programId: new web3.PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
				data: Buffer.from(btoa(JSON.stringify(quoteReq)))
			})
		)

		return transaction
	}

	buildClaimTransaction(payer, mint, priorityFee) {
		const transaction = new web3.Transaction()
		const unitLimit = 160_000
		const unitPrice = Math.floor(priorityFee / unitLimit)

		const dstAcc = getAssociatedTokenAddressSync(mint, payer, true, TOKEN_2022_PROGRAM_ID)

		transaction.add(
			web3.ComputeBudgetProgram.setComputeUnitLimit({units: unitLimit}),
			web3.ComputeBudgetProgram.setComputeUnitPrice({microLamports: unitPrice}),
			createWithdrawWithheldTokensFromMintInstruction(mint, dstAcc, payer, [], TOKEN_2022_PROGRAM_ID),
		)
		return transaction
	}
}