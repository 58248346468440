import {web3} from "@project-serum/anchor";
import {
	createAssociatedTokenAccountInstruction,
	createInitializeMintInstruction,
	createMintToInstruction,
	getAssociatedTokenAddressSync,
	MINT_SIZE,
	TOKEN_PROGRAM_ID
} from "@solana/spl-token";
import {createCreateMetadataAccountV3Instruction, DataV2, PROGRAM_ID} from "@metaplex-foundation/mpl-token-metadata";


export class CreateMintV1 {
	name: string
	symbol: string
	metadataUri: string
	decimals: number
	totalSupply: bigint

	mintAuthority: web3.PublicKey
	freezeAuthority: web3.PublicKey | null

	sellerFeeBasisPoints: number = 0;

	//Do we mint the total supply to the user
	mintTotalSupply: boolean

	creators : [] | null = null;

	constructor(name: string, symbol: string, metadataUri: string, totalSupply: bigint, mintAuthority: web3.PublicKey, freezeAuthority: web3.PublicKey | null, decimals = 6, mintTotalSupply = true) {
		this.name = name
		this.symbol = symbol
		this.metadataUri = metadataUri
		this.totalSupply = totalSupply
		this.decimals = decimals
		this.mintTotalSupply = mintTotalSupply
		this.mintAuthority = mintAuthority
		this.freezeAuthority = freezeAuthority
	}

	setSellerFeeBasisPoints(bp: number) {
		this.sellerFeeBasisPoints = bp
	}

	setCreators(creators: []) {
		this.creators = creators
	}
}

export default class TokenV1Client {

	FEE_ACCOUNT = new web3.PublicKey("FLUXR4McuD2iXyP3wpP4XTjSWmB86ppMiyoA52UA9bKb")

	connection: web3.Connection

	constructor(connection: web3.Connection) {
		this.connection = connection
	}

	async getCreateMintTransaction(owner: web3.PublicKey, tokenMint: web3.PublicKey, config: CreateMintV1, priorityFee: number, metadataCID: string) {
		const mintLamports = await this.connection.getMinimumBalanceForRentExemption(MINT_SIZE);

		const ata = getAssociatedTokenAddressSync(tokenMint, owner, true, TOKEN_PROGRAM_ID)

		const [metadataPDA] = web3.PublicKey.findProgramAddressSync([Buffer.from("metadata"), PROGRAM_ID.toBuffer(), tokenMint.toBuffer()], PROGRAM_ID)

		const ON_CHAIN_METADATA = {
			name: config.name,
			symbol: config.symbol,
			uri: config.metadataUri,
			sellerFeeBasisPoints: config.sellerFeeBasisPoints,
			uses: null,
			creators: config.creators,
			collection: null,
		} as DataV2;


		const unitLimit = 100_000
		const unitPrice = Math.floor(priorityFee / unitLimit)
		const txn = new web3.Transaction().add(
			web3.ComputeBudgetProgram.setComputeUnitLimit({units: unitLimit}),
			web3.ComputeBudgetProgram.setComputeUnitPrice({microLamports: unitPrice}),
			web3.SystemProgram.transfer({
				fromPubkey: owner,
				toPubkey: this.FEE_ACCOUNT,
				lamports: 100_000_000,
			}),
			new web3.TransactionInstruction({
				keys: [],
				programId: new web3.PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
				data: Buffer.from(metadataCID)
			}),
			web3.SystemProgram.createAccount({
				fromPubkey: owner,
				newAccountPubkey: tokenMint,
				space: MINT_SIZE,
				lamports: mintLamports,
				programId: TOKEN_PROGRAM_ID,
			}),
		);

		txn.add(createInitializeMintInstruction(tokenMint, config.decimals, config.mintAuthority, config.freezeAuthority, TOKEN_PROGRAM_ID),
			createCreateMetadataAccountV3Instruction({
				metadata: metadataPDA,
				mint: tokenMint,
				mintAuthority: config.mintAuthority,
				payer: owner,
				updateAuthority: owner,
			}, {
				createMetadataAccountArgsV3:
					{
						data: ON_CHAIN_METADATA,
						isMutable: true,
						collectionDetails: null
					}
			}, PROGRAM_ID),
			createAssociatedTokenAccountInstruction(owner, ata, owner, tokenMint, TOKEN_PROGRAM_ID),
		)

		if (config.mintTotalSupply) {
			txn.add(createMintToInstruction(tokenMint, ata, owner, config.totalSupply, [], TOKEN_PROGRAM_ID))
		}

		const bhash = await this.connection.getLatestBlockhash("confirmed")
		txn.feePayer = owner
		txn.recentBlockhash = bhash.blockhash
		return txn
	}


	/**
	 * Returns the token mints metadata PDA
	 * @param mint
	 * @param metadataProgram
	 */
	getMetadataPDA(mint: web3.PublicKey, metadataProgram = PROGRAM_ID) {
		//@ts-ignore
		const [metaPDA] = web3.PublicKey.findProgramAddressSync(["metadata", metadataProgram.toBuffer(), mint.toBuffer()], metadataProgram)
		return metaPDA
	}
}