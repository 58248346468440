import {web3} from "@project-serum/anchor";

export class RugCheckClient {

	baseURI = "https://api.rugcheck.xyz/v1"

	async summary(mint: web3.PublicKey): Promise<any> {
		const resp = await fetch(`${this.baseURI}/tokens/${mint}/report/summary`)
		if (!resp)
			return

		return await resp.json()
	}

	async report(mint: web3.PublicKey): Promise<any> {
		const resp = await fetch(`${this.baseURI}/tokens/${mint}/report`)
		if (!resp)
			return

		return await resp.json()
	}

}