export default class HotOrNotClient {
	baseURI = "http://localhost:8091"

	getVote(sha) {
		return fetch(`${this.baseURI}/votes/${sha}`).then(r => r.json())
	}

	getImageUri(sha) {
		return `${this.baseURI}/static/votes/fluxbots/${sha}.png`
	}

	getRandom() {
		return fetch(`${this.baseURI}/collections/alphabots/random`)
	}

	getHistory(sha, page = 0) {
		return fetch(`${this.baseURI}/votes/history/${sha}?page=${page}`).then(r => r.json())
	}

	getLeaderboard(page = 0) {
		return fetch(`${this.baseURI}/votes/leaderboard?page=${page}`).then(r => r.json())
	}

	doVote(walletAddr, a, b, vote) {
		return fetch(`${this.baseURI}/votes/${a}/${b}`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				wallet: walletAddr,
				vote: vote
			})
		})
	}


	getShaHeader(r) {
		for (let pair of r.headers.entries()) { // accessing the entries
			if (pair[0] === 'x-sha') { // key I'm looking for in this instance
				return pair[1]
			}
		}
	}
}