import {web3} from "@project-serum/anchor";



export const SWAP_PROGRAM_ID = new web3.PublicKey("FLUXubRmkEi2q6K3Y9kBPg9248ggaZVsoSFhtJHSrm1X") //Fluxbeam SWAP
export const OPENBOOK_PROGRAM_ID = new web3.PublicKey("3hbVj9SJchvF7avy2g1AzRVama8vxZPbk83kpnbJMTa4") //Openbook

export const WSOL = new web3.PublicKey("So11111111111111111111111111111111111111112")
export const USDC = new web3.PublicKey("EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v")
export const MSOL = new web3.PublicKey("mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So")
export const FLUXB = new web3.PublicKey("FLUXBmPhT3Fd1EDVFdg46YREqHBeNypn1h4EbnTzWERX")


export const METADATA_V1_PROGRAM_ID = new web3.PublicKey("metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s")
export const METADATA_2022_PROGRAM_ID = new web3.PublicKey("META4s4fSmpkTbZoUsgC1oBnWB31vQcmnN8giPw51Zu")
// export const METADATA_2022_PROGRAM_ID = new web3.PublicKey("M1tgEZCz7fHqRAR3G5RLxU6c6ceQiZyFK7tzzy4Rof4")



export function defaultSOL(addr: string, amount: number) {
	const uiAmount = amount / Math.pow(10, 9)
	return {
		address: WSOL,
		pubkey: WSOL,
		account: {
			data: {
				parsed: {
					info: {
						mint: "So11111111111111111111111111111111111111112",
						owner: addr,
						tokenAmount: {
							amount: `${amount}`,
							decimals: 9,
							uiAmount: uiAmount,
							uiAmountString: `${uiAmount}`
						}
					}
				}
			}
		}
	}
}