import {web3} from "@project-serum/anchor";
import {TokenMigrationLayout} from "@/api/token_migration/layouts";
import {struct, u64, u8} from "@/api/marshmallow";
import {createAssociatedTokenAccountInstruction, getAssociatedTokenAddressSync, TOKEN_2022_PROGRAM_ID, TOKEN_PROGRAM_ID} from "@solana/spl-token";
import BN from "bn.js";
import {PROGRAM_ID} from "@metaplex-foundation/mpl-token-metadata";
import {CreateMint, Token2022Client, TOKEN_METADATA_PROGRAM_ID} from "@/api/token2022/token_2022";
import {Client as TokenSwapClient} from "@/api/token_swap";
import {sha256} from "js-sha256"
import {PoolConfig, PoolFee, TokenInput} from "@/api/token_swap/layouts";

/**
 * Client to handle all migration tasks
 */
export default class Client {

	connection: web3.Connection

	migrateProgramID = new web3.PublicKey("2LcCb4ZwqF6RfWdRPmPAqrxxyx9zi279i294YPKHDdVx")

	token2022: Token2022Client;
	swapClient: TokenSwapClient;

	constructor(connection: web3.Connection) {
		this.connection = connection

		this.token2022 = new Token2022Client(connection)
		this.swapClient = new TokenSwapClient(connection)
	}


	async getMigrations() {
		const resp = await this.connection.getProgramAccounts(this.migrateProgramID)
		return resp.map((m) => {
			return {pubkey: m.pubkey, account: TokenMigrationLayout.decode(m.account.data)}
		})
	}

	async getMigration(address: web3.PublicKey) {
		const resp = await this.connection.getAccountInfo(address, "confirmed")
		if (!resp)
			return resp

		return TokenMigrationLayout.decode(resp?.data!)
	}

	getMigrationKey(mintA: web3.PublicKey) {
		//@ts-ignore - stop bitching about strings
		const [migrationAddr] = web3.PublicKey.findProgramAddressSync(["migration", mintA.toBuffer()], this.migrateProgramID)
		return migrationAddr
	}

	getMigrationAuthorityKey(migrationAddr: web3.PublicKey) {
		//@ts-ignore - stop bitching about strings
		const [key] = web3.PublicKey.findProgramAddressSync(["authority", migrationAddr.toBuffer()], this.migrateProgramID)
		return key
	}

	async createMigration(
		mintA: web3.PublicKey,
		owner: web3.PublicKey,
		mintBConfig: CreateMint,
		priorityFee: number,
		metadataCID: string,
	) {
		const mintB = web3.Keypair.generate()

		//@ts-ignore - stop bitching about strings
		const [migration, bump] = web3.PublicKey.findProgramAddressSync(["migration", mintA.toBuffer()], this.migrateProgramID)

		const transaction = await this.token2022.getCreateMintTransaction(owner, mintB.publicKey, mintBConfig, priorityFee, metadataCID)

		//@ts-ignore
		const {pool, txn, signers} = await this._createMigrationPool(
			owner,
			migration,
			new TokenInput(mintA, 1, TOKEN_PROGRAM_ID),
			new TokenInput(mintB.publicKey, 1)
		)
		transaction.add(...txn.instructions)

		//Create the migration
		transaction.add(this.createMigrationPoolInstruction(
			migration,
			pool,
			mintA,
			mintB.publicKey,
			owner,
			bump
		))

		signers.push(mintB)
		return {
			txn: transaction,
			signers: signers
		}
	}

	async _createMigrationPool(owner: web3.PublicKey, migration: web3.PublicKey, tokenA: TokenInput, tokenB: TokenInput) {
		const resp = await this.swapClient.createPoolTransactions(
			owner,
			migration,
			tokenA,
			tokenB,
			new PoolConfig(
				new PoolFee(0, 1000),
				new PoolFee(0, 1000),
				new PoolFee(0, 1000),
				new PoolFee(0, 1000),
				1,
			)
		)
		if (!resp)
			return resp

		return resp[0]
	}

	async migrateTokens(
		mintA: web3.PublicKey,
		owner: web3.PublicKey,
		amountIn: BN
	) {
		const transaction = new web3.Transaction()

		const migrationKey = this.getMigrationKey(mintA)
		const mintAuthority = migrationKey

		//Get migration details
		const migration = await this.getMigration(migrationKey)

		//ATA's
		//@ts-ignore
		const mintAAta = getAssociatedTokenAddressSync(migration?.v1TokenMint!, owner, true, TOKEN_PROGRAM_ID)

		//@ts-ignore
		const mintBAta = getAssociatedTokenAddressSync(migration?.v2TokenMint!, owner, true, TOKEN_2022_PROGRAM_ID)

		const dstIfo = await this.connection.getAccountInfo(mintBAta, "confirmed")
		if (!dstIfo) {
			//@ts-ignore
			transaction.add(createAssociatedTokenAccountInstruction(owner, mintBAta, owner, migration?.v2TokenMint!, TOKEN_2022_PROGRAM_ID))
		}
		transaction.add(this.createMigrateTokensInstruction(
			migrationKey,
			//@ts-ignore
			migration?.v1TokenMint!,
			mintAAta,
			//@ts-ignore
			migration?.v2TokenMint!,
			mintBAta,
			owner,
			mintAuthority,
			TOKEN_PROGRAM_ID,
			TOKEN_2022_PROGRAM_ID,
			amountIn
		))

		return transaction
	}


	createMigrationPoolInstruction(
		migration: web3.PublicKey,
		pool: web3.PublicKey,
		v1TokenMint: web3.PublicKey,
		v2TokenMint: web3.PublicKey,
		owner: web3.PublicKey,
		bump: number,
	): web3.TransactionInstruction {
		const dataLayout = struct([
			u8('bump'),
		]);

		const desc = this.ixDescriminator("create_migration")

		const buffer = Buffer.alloc(desc.length + dataLayout.span);
		const len = dataLayout.encode({
			bump: bump
		}, buffer);
		const data = Buffer.concat([desc, buffer.slice(0, len)]);


		const v1TokenMetadata = this.getMetadataPDA(v1TokenMint, PROGRAM_ID) //V1

		const keys = [
			{pubkey: migration, isSigner: false, isWritable: true},
			{pubkey: pool, isSigner: false, isWritable: false},
			{pubkey: v1TokenMint, isSigner: false, isWritable: true},
			{pubkey: v2TokenMint, isSigner: false, isWritable: true},
			{pubkey: v1TokenMetadata, isSigner: false, isWritable: true},

			{pubkey: owner, isSigner: true, isWritable: true},

			{pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false},
			{pubkey: TOKEN_2022_PROGRAM_ID, isSigner: false, isWritable: false},

			{pubkey: web3.SystemProgram.programId, isSigner: false, isWritable: false},
		];

		return new web3.TransactionInstruction({
			keys,
			programId: this.migrateProgramID,
			data,
		});
	}

	createMigrateTokensInstruction(
		migration: web3.PublicKey,
		v1TokenMint: web3.PublicKey,
		v1TokenAccount: web3.PublicKey,
		v2TokenMint: web3.PublicKey,
		v2TokenAccount: web3.PublicKey,
		owner: web3.PublicKey,
		mintAuthority: web3.PublicKey,
		v1TokenProgramId: web3.PublicKey,
		v2TokenProgramId: web3.PublicKey,
		amountIn: BN,
	): web3.TransactionInstruction {
		const dataLayout = struct([
			u64('amountIn'),
		]);

		const desc = this.ixDescriminator("migrate_tokens")

		const buffer = Buffer.alloc(desc.length + dataLayout.span);
		const len = dataLayout.encode({
			amountIn: amountIn
		}, buffer);
		const data = Buffer.concat([desc, buffer.slice(0, len)]);

		const keys = [
			{pubkey: migration, isSigner: false, isWritable: true},
			{pubkey: v1TokenAccount, isSigner: false, isWritable: true},
			{pubkey: v2TokenAccount, isSigner: false, isWritable: true},
			{pubkey: v1TokenMint, isSigner: false, isWritable: true},
			{pubkey: v2TokenMint, isSigner: false, isWritable: true},

			{pubkey: mintAuthority, isSigner: false, isWritable: false},
			{pubkey: v1TokenProgramId, isSigner: false, isWritable: false},
			{pubkey: v2TokenProgramId, isSigner: false, isWritable: false},

			{pubkey: owner, isSigner: true, isWritable: true},
		];

		return new web3.TransactionInstruction({
			keys,
			programId: this.migrateProgramID,
			data,
		});
	}


	/**
	 * Returns the token mints metadata PDA
	 * @param mint
	 * @param metadataProgram
	 */
	getMetadataPDA(mint: web3.PublicKey, metadataProgram = TOKEN_METADATA_PROGRAM_ID) {
		//@ts-ignore
		const [metaPDA] = web3.PublicKey.findProgramAddressSync(["metadata", metadataProgram.toBuffer(), mint.toBuffer()], metadataProgram)
		return metaPDA
	}

	/**
	 * Convert to instruction descriminator
	 * Should be SNAKE_CASE (_)
	 * @param methodName
	 */
	ixDescriminator(methodName: string) {
		return Buffer.from(sha256.digest(`global:${methodName}`)).slice(0, 8)
	}
}