import {struct, u16, u8} from "@solana/buffer-layout";
import {u64} from "@solana/buffer-layout-utils";
import {TOKEN_2022_PROGRAM_ID} from "@solana/spl-token";
import {web3} from "@project-serum/anchor";

enum IX {
	TransferFeeExtension = 26
}

enum TIX {
	SetTransferFee = 5
}

// SetTransferFee
export interface SetTransferFeeInstructionData {
	instruction: IX.TransferFeeExtension; // TransferFeeExtension
	transferFeeInstruction: TIX.SetTransferFee; //SetTransferFee
	transferFeeBasisPoints: number;
	maximumFee: bigint;
}

export const setTransferFeeInstructionData = struct<SetTransferFeeInstructionData>([
	u8('instruction'),
	u8('transferFeeInstruction'),
	u16('transferFeeBasisPoints'),
	u64('maximumFee'),
]);

export function createSetTransferFeeInstruction(
	mint: web3.PublicKey,
	authority: web3.PublicKey,
	transferFeeBasisPoints: number,
	maximumFee: bigint,
	signers: (web3.Signer | web3.PublicKey)[] = [],
	programId = TOKEN_2022_PROGRAM_ID
): web3.TransactionInstruction {
	if (programId !== TOKEN_2022_PROGRAM_ID) {
		throw new Error("TokenUnsupportedInstructionError");
	}
	const data = Buffer.alloc(setTransferFeeInstructionData.span);
	setTransferFeeInstructionData.encode(
		{
			instruction: IX.TransferFeeExtension,
			transferFeeInstruction: TIX.SetTransferFee,
			transferFeeBasisPoints: transferFeeBasisPoints,
			maximumFee: maximumFee,
		},
		data
	);

	console.log("DATA", {
		data,
		dStr: data.toString("utf-8")
	})

	const keys = addSigners([{pubkey: mint, isSigner: false, isWritable: true}], authority, signers);
	return new web3.TransactionInstruction({keys, programId, data});
}

export function addSigners(
	keys: web3.AccountMeta[],
	ownerOrAuthority: web3.PublicKey,
	multiSigners: (web3.Signer | web3.PublicKey)[]
): web3.AccountMeta[] {
	if (multiSigners.length) {
		keys.push({pubkey: ownerOrAuthority, isSigner: false, isWritable: false});
		for (const signer of multiSigners) {
			keys.push({
				pubkey: signer instanceof web3.PublicKey ? signer : signer.publicKey,
				isSigner: true,
				isWritable: false,
			});
		}
	} else {
		keys.push({pubkey: ownerOrAuthority, isSigner: true, isWritable: false});
	}
	return keys;
}


/** A decoded, valid SetTransferFee instruction */
export interface DecodedSetTransferFeeInstruction {
	programId: web3.PublicKey;
	keys: {
		mint: web3.AccountMeta;
	};
	data: {
		instruction: IX.TransferFeeExtension;
		transferFeeInstruction: TIX.SetTransferFee;
		transferFeeBasisPoints: number;
		maximumFee: bigint;
	};
}

/**
 * Decode a SetTransferFee instruction and validate it
 *
 * @param instruction Transaction instruction to decode
 * @param programId   SPL Token program account
 *
 * @return Decoded, valid instruction
 */
export function decodeSetTransferFeeInstruction(
	instruction: web3.TransactionInstruction,
	programId: web3.PublicKey
): DecodedSetTransferFeeInstruction {
	if (!instruction.programId.equals(programId)) throw new Error("TokenInvalidInstructionProgramError");
	if (instruction.data.length !== setTransferFeeInstructionData.span)
		throw new Error("TokenInvalidInstructionDataError");

	const {
		keys: {mint},
		data,
	} = decodeSetTransferFeeInstructionUnchecked(instruction);
	if (
		data.instruction !== IX.TransferFeeExtension ||
		data.transferFeeInstruction !== TIX.SetTransferFee
	)
		throw new Error("TokenInvalidInstructionTypeError");
	if (!mint) throw new Error("TokenInvalidInstructionKeysError");

	return {
		programId,
		keys: {
			mint,
		},
		data,
	};
}

/** A decoded, valid SetTransferFee instruction */
export interface DecodedSetTransferFeeInstructionUnchecked {
	programId: web3.PublicKey;
	keys: {
		mint: web3.AccountMeta;
	};
	data: {
		instruction: IX.TransferFeeExtension;
		transferFeeInstruction: TIX.SetTransferFee;
		transferFeeBasisPoints: number;
		maximumFee: bigint;
	};
}

/**
 * Decode a SetTransferFee instruction without validating it
 *
 * @param instruction Transaction instruction to decode
 *
 * @return Decoded, non-validated instruction
 */
export function decodeSetTransferFeeInstructionUnchecked({programId, keys: [mint], data,}: web3.TransactionInstruction): DecodedSetTransferFeeInstructionUnchecked {
	const {instruction, transferFeeInstruction, transferFeeBasisPoints, maximumFee} = setTransferFeeInstructionData.decode(data);
	return {
		programId,
		keys: {
			mint,
		},
		data: {
			instruction,
			transferFeeInstruction,
			transferFeeBasisPoints,
			maximumFee
		},
	};
}