import {blob, GetStructureSchema, publicKey, struct, u64, u8} from "@/api/marshmallow";


export const TokenMigrationLayout = struct([
	blob(8), //Anchor diff
	publicKey('v1TokenMint'),
	publicKey('v2TokenMint'),
	u64('tokenSupply'),
	u64('migratedAmount'),
	u8('bump'),
	publicKey('authority'),
]);

export type TokenMigrationPoolLayout = typeof TokenMigrationLayout;
export type TokenMigrationPool = GetStructureSchema<TokenMigrationPoolLayout>;