export default class FluxBotClient {

	baseURI = "https://bot-api.fluxbeam.xyz"
	// baseURI = "http://localhost:8081"

	getPoints(walletAddr) {
		return fetch(`${this.baseURI}/users/${walletAddr}/points`).then(r => r.json())
	}

	getReferrals(walletAddr) {
		return fetch(`${this.baseURI}/users/${walletAddr}/referrals`).then(r => r.json())
	}

	getRank(walletAddr) {
		return fetch(`${this.baseURI}/users/${walletAddr}/ranking`).then(r => r.json())
	}

	getPointsLeaderboard(page = 0) {
		return fetch(`${this.baseURI}/leaderboards/points?page=${page}`).then(r => r.json())
	}

	getReferralLeaderboard(page = 0) {
		return fetch(`${this.baseURI}/leaderboards/referrals?page=${page}`).then(r => r.json())
	}

	getStats() {
		return fetch(`${this.baseURI}/stats`).then(r => r.json())
	}

}