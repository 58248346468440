import {struct} from '@solana/buffer-layout';
import {publicKey, bool} from '@solana/buffer-layout-utils';
import {PublicKey} from "@solana/web3.js";
import {ExtensionType, getExtensionData, Mint} from "@solana/spl-token";


/** TransferHook as stored by the program */
export interface ConfidentialTransferMint {
	auditorElgamalPubkey: PublicKey;
	authority: PublicKey;
	autoApproveNewAccounts: boolean;
}

/** Buffer layout for de/serializing a confidential transfer extension */
export const ConfidentialTransferMintLayout = struct<ConfidentialTransferMint>([publicKey('auditorElgamalPubkey'), publicKey('authority'), bool('autoApproveNewAccounts')]);

export const CONFIDENTIAL_TRANSFER_MINT_SIZE = ConfidentialTransferMintLayout.span;

export function getConfidentialTransferMint(mint: Mint): ConfidentialTransferMint | null {
	const extensionData = getExtensionData(ExtensionType.ConfidentialTransferMint, mint.tlvData);
	if (extensionData !== null) {
		return ConfidentialTransferMintLayout.decode(extensionData);
	} else {
		return null;
	}
}
