import {blob, GetStructureSchema, publicKey, struct, u64, u8} from "@/api/marshmallow";


export const PoolLayout = struct([
	blob(8), //Anchor diff
	u8('bump'),
	publicKey('nftCollection'),
	publicKey('tokenMint'),
]);

export type PoolLayout = typeof PoolLayout;
export type Pool = GetStructureSchema<PoolLayout>;


export const Market = struct([
	blob(8), //Anchor diff
	u8('bump'),
	publicKey('nftCollection'),
	publicKey('quoteMint'),
	publicKey('swapPool'),
]);

export type MarketLayout = typeof Market;
export type Market = GetStructureSchema<MarketLayout>;