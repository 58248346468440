import {web3} from "@project-serum/anchor";
import {baseURI} from "@/api/fluxbeam/constants";

export class Spl22 {

	async search(query: string, page: number = 0) {
		return await (await fetch(this.uri(`search?q=${query}&page=${page}`))).json()
	}

	async pools(page: number, limit = 100) {
		return await (await fetch(this.uri(`pools?limit=${limit}&page=${page}`))).json()
	}

	async pool(pk: web3.PublicKey) {
		return (await fetch(this.uri(`pools/${pk}`))).json()
	}

	async poolByTicker(ticker: string) {
		return (await fetch(this.uri(`pool-tickers/${ticker}`))).json()
	}

	async poolByMint(ticker: string) {
		return (await fetch(this.uri(`pool-mints/${ticker}`))).json()
	}

	// async pool(pk: web3.PublicKey) {
	// 	return (await fetch(this.uri(`pools/${pk}`))).json()
	// }

	async listings(ticker: string) {
		return (await fetch(this.uri(`pool-listings/${ticker.toLowerCase()}`))).json()
	}

	uri(endpoint: string) {
		return `${baseURI}/spl22/${endpoint}`
	}
}